<template>
  <div v-if="category !== null">
    <b-card>

      <b-form>
        <b-row>

          <b-col
              cols="12"
              class="mb-1"
          >
            <h3>
              نام دسته بندی
              ({{ category.type === 1 ? 'بلاگ' : 'مجله' }})
            </h3>
          </b-col>

          <!-- Field: name -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="نام"
                label-for="name"
            >
              <b-form-input
                  id="name"
                  v-model="category.name"
              />
            </b-form-group>
          </b-col>

          <!-- Field: type -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="نوع"
                label-for="type"
            >
              <v-select
                  disabled
                  v-model="category.type"
                  :options="typeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="type"
              />
            </b-form-group>
          </b-col>

          <!-- Field: schema -->
          <b-col
              v-if="category.type === 2"
              cols="12"
              md="4"
          >
            <b-form-group
                label="الگو"
                label-for="schema"
            >
              <v-select
                  v-model="category.schema"
                  :options="schemaOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="type"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card>
      <h3>سئو</h3>

      <b-form >
        <h2 class="mb-2">سئو</h2>
        <b-row>
          <b-col md="6" class="">
            <b-form-group
                label="عنوان"
                label-for="blog-edit-title"
                class=""
            >
              <b-form-input
                  id="blog-edit-seoTitle"
                  v-model="category.seoTitle"
              />
            </b-form-group>
            <span class="" :class="{'text-danger':category.seoTitle.length>60}">تعداد کاراکترهای مجاز <small>{{ category.seoTitle.length }}</small><small
                class="text-secondary">/</small><small class="text-secondary">60</small></span>
          </b-col>
          <b-col md="6" class="d-flex flex-row justify-content-between align-items-start">
            <b-form-group
                label="آدرس"
                label-for="blog-edit-title"
                class="w-100"
            >
              <b-form-input
                  id="blog-edit-seourl"
                  v-model="allCharactersToDash"
                  v-on:focus="$event.target.select()"
                  ref="myInput"
              />
            </b-form-group>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="h-50 mt-1 ml-2"
                @click="copyText"
            >
              کپی
            </b-button>
          </b-col>
          <!-- Field:  description -->
          <b-col cols="12" class="mt-2">
            <b-form-group
                label="توضیحات"
                label-for="blog-content"
                class="mb-2"
            >
              <b-form-textarea
                  v-model="category.seoDescription"
              ></b-form-textarea>
            </b-form-group>
            <span class="" :class="{'text-danger':category.seoDescription.length>160}">تعداد کاراکترهای مجاز <small>{{ category.seoDescription.length }}</small><small
                class="text-secondary">/</small><small class="text-secondary">160</small></span>
          </b-col>
        </b-row>
      </b-form>

      <h2>نحوه نمایش در گوگل</h2>

      <div class="col-md-12 my-3">
        <!--- serp Desktop --->
        <div class="serp-preview d-lg-flex p-3 d-none flex-column align-items-end">
          <div class="serp-title" >
            <h4 style="color: rgb(26, 13, 171)!important">
              {{category.seoTitle}}
            </h4>
          </div>
          <div class="serp-url">
            <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
              <span style="overflow-wrap: anywhere">{{category.seourl.replaceAll(' ','-')}}/</span>
              <span>
                             {{ 'https://enzamode.ir/magazine' }}
                        <span class="serp-arrow"></span>
                      </span>

            </h5>

          </div>
          <div class="serp-description">
            <h6 style="overflow-wrap: anywhere">
              {{ category.seoDescription }}
            </h6>
          </div>
        </div>
        <!--- serp Mobile --->
        <div class="serp-preview d-lg-none  p-3 d-flex flex-column align-items-end">
          <div class="serp-title">
            <h6>
              {{category.seoTitle}}
            </h6>
          </div>
          <div class="serp-url d-flex align-items-center flex-wrap" >

            <small >{{category.seourl.replaceAll(' ','-')}}/</small>
            <small>
              {{ 'https://enzamode.ir/magazine' }}
              <small class="serp-arrow"></small>
            </small>
          </div>
          <div class="serp-description">
            <small>
              {{ category.seoDescription }}
            </small>
          </div>
        </div>
      </div>

      <!-- Action Buttons -->
      <b-button
          variant="primary"
          class="mt-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="updateCategory"
      >
        ذخیره تغییرات
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BForm, BModal,BFormTextarea,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {BlogCategoryUpdateRequest} from "@/libs/Api/Blog";
import {BlogCategoryGetRequest} from "@/libs/Api/Blog";


export default {
  name: "categories-edit",
  title: "ویرایش دسته بندی بلاگ",
  data() {
    return {
      category: null,
      categoryProperties: null,
      categoryId: this.$route.params.id,
      addNewCategoryProperty: false,
      schemaOptions: [
        {label: '1', value: 1},
        {label: '2', value: 2},
        {label: '3', value: 3},
      ],
      typeOptions: [
        {label: 'بلاگ', value: 1},
        {label: 'مجله', value: 2},
      ],
      myTableColumns: [
        {key: 'categoryPropertyId', label: 'شناسه'},
        {key: 'name', label: 'نام'},
        {key: 'delete', label: 'حذف'},
        {key: 'edit', label: 'ویرایش'},
      ],
      defaultCreateCategoryPropertyData: {
        isDeleted: false,
        categoryPropertyId: 0,
        categoryId: this.$route.params.id,
        name: ""
      },
      updateCategoryPropertyData: null,
    }
  },
  computed: {
    allCharactersToDash: {
      get: function () {
        return this.category.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.category.seourl = newValue
          // Remove all characters that are NOT number
          this.category.seourl = this.category.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.category.seourl = this.category.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.category.seourl === "") {
          this.category.seourl = null;
        }
      },
    },
  },
  async created() {
    await this.getCategory();
  },
  methods: {
    async getCategory() {
      let _this = this;

      let blogCategoryGetRequest = new BlogCategoryGetRequest(_this);
      blogCategoryGetRequest.setId(_this.categoryId);
      await blogCategoryGetRequest.fetch(function (content) {
        _this.category = content
      }, function (error) {
        console.log(error)
      })
    },
    async updateCategory() {
      let _this = this;

      let blogCategoryUpdateRequest = new BlogCategoryUpdateRequest(_this);
      blogCategoryUpdateRequest.setParams(_this.category);
      await blogCategoryUpdateRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تغییرات اعمال شد.`,
          },
        })
        _this.getCategory()
      }, function (error) {
        console.log(error)
      })
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BFormGroup,
    BBadge,
    BForm,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormTextarea,
    vSelect,
  }
}
</script>

<style scoped>

</style>
